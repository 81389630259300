import React from "react";
import { Typography, Box, Grid } from "@material-ui/core";
import { Link } from "gatsby";
import styles from "./GridBox.module.scss";
import styles2 from "../../css/main.module.scss";
import { createCdnURL } from "../../config/app";

const GridBox = (props) => {
  const { title, items } = props;
  return (
    <Box
      className={[styles.brandBlocks, styles2.padded10].join(" ")}
      style={{ background: "white", margin: "5px 0 5px 0" }}
    >
      <Typography
        variant="h6"
        component="h1"
        className={styles.dashed}
        style={{ fontWeight: "bold  " }}
      >
        {title}
      </Typography>

      <Grid container style={{ padding: "20px 0px" }} spacing={1}>
        {items.map((item, i) => (
          <Grid md={2} sm={3} xs={6} item key={i}>
            <Link to={`${item.url || "#"}`} title={item.title}>
              <img
                loading="lazy"
                alt={item.title}
                src={createCdnURL(
                  "large",
                  item.cover_image || item.image || item.photo || ""
                )}
              />
            </Link>
          </Grid>
        ))}
        {/* <Grid md={2} sm={3} xs={6} item><Link href="#"><img alt="image_al" src="https://images-eu.ssl-images-amazon.com/images/G/39/electronics/store/kamja/LP/Wireless/Logo/samsung.jpg"/></Link></Grid>
                <Grid md={2} sm={3} xs={6} item><Link href="#"><img alt="image_al" src="https://images-eu.ssl-images-amazon.com/images/G/39/electronics/store/kamja/LP/Wireless/Logo/apple.jpg"/></Link></Grid>
                <Grid md={2} sm={3} xs={6} item><Link href="#"><img alt="image_al" src="https://images-eu.ssl-images-amazon.com/images/G/39/electronics/store/kamja/LP/Wireless/Logo/huawei.jpg"/></Link></Grid>
                <Grid md={2} sm={3} xs={6} item><Link href="#"><img alt="image_al" src="https://images-eu.ssl-images-amazon.com/images/G/39/electronics/store/kamja/LP/Wireless/Logo/oneplus.jpg"/></Link></Grid>
                <Grid md={2} sm={3} xs={6} item><Link href="#"><img alt="image_al" src="https://images-eu.ssl-images-amazon.com/images/G/39/electronics/store/kamja/LP/Wireless/Logo/motorola.jpg"/></Link></Grid>
                <Grid md={2} sm={3} xs={6} item><Link href="#"><img alt="image_al" src="https://images-eu.ssl-images-amazon.com/images/G/39/electronics/store/kamja/LP/Wireless/Logo/xiaomi.jpg"/></Link></Grid>
                <Grid md={2} sm={3} xs={6} item><Link href="#"><img alt="image_al" src="https://images-eu.ssl-images-amazon.com/images/G/39/electronics/store/kamja/LP/Wireless/Logo/nokia.jpg"/></Link></Grid>
                <Grid md={2} sm={3} xs={6} item><Link href="#"><img alt="image_al" src="https://images-eu.ssl-images-amazon.com/images/G/39/electronics/store/kamja/LP/Wireless/Logo/vivo.jpg"/></Link></Grid>
                <Grid md={2} sm={3} xs={6} item><Link href="#"><img alt="image_al" src="https://images-eu.ssl-images-amazon.com/images/G/39/electronics/store/kamja/LP/Wireless/Logo/oppo.jpg"/></Link></Grid>
                <Grid md={2} sm={3} xs={6} item><Link href="#"><img alt="image_al" src="https://images-eu.ssl-images-amazon.com/images/G/39/electronics/store/kamja/LP/Wireless/Logo/honor.jpg"/></Link></Grid>
                <Grid md={2} sm={3} xs={6} item><Link href="#"><img alt="image_al" src="https://images-eu.ssl-images-amazon.com/images/G/39/electronics/store/kamja/LP/Wireless/Logo/realme.jpg"/></Link></Grid>
                <Grid md={2} sm={3} xs={6} item><Link href="#"><img alt="image_al" src="https://images-eu.ssl-images-amazon.com/images/G/39/electronics/store/kamja/LP/Wireless/Logo/energizer.jpg"/></Link></Grid> */}
      </Grid>
    </Box>
  );
};

export default GridBox;
