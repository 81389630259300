const extractURLInfo = (url) => {
  let name = url.split("/").pop();
  let parts = name.split(".");
  return { ext: parts[1], id: parts[0] };
};

const createBannerImage = (url, size) => {
  let { ext, id } = extractURLInfo(url);
  if (size === "medium") {
    return `https://cdn.electroniks.pk/images/${id}/banner-medium.${ext}`;
  }

  return `https://cdn.electroniks.pk/images/${id}/banner-large.${ext}`;
};

export { createBannerImage };
