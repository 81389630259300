import React from "react";
import { withWidth } from "@material-ui/core";
import HomePage from "../home";
import Layout from "../components/Layout";

function Home(props) {
  const { pageContext } = props;

  return (
    <Layout sideMenuCollections={pageContext.sideMenuCollections}>
      <HomePage homePageCollections={pageContext.homePageCollections} />
    </Layout>
  );
}

export default withWidth()(Home);
