import React from "react";
import { Grid, Container, withWidth, Box } from "@material-ui/core";
import GridBox from "./Collection/GridBox";
import Carousel from "./Collection/Carousel";
import LazyLoad from "react-lazyload";
import Slider from "./Collection/Slider";
import Banner from "./Collection/Banner";
import Html from "./Collection/Html";

function renderCollection(width, collection) {
  if (collection.type === "see_more") {
    return (
      <Carousel
        title={collection.title}
        items={collection.items}
        seeMoreLink={collection.url || "#"}
      />
    );
  }

  if (collection.type === "grid") {
    return <GridBox title={collection.title} items={collection.items} />;
  }

  if (collection.type === "slider") {
    return (
      <Slider title={collection.title} items={collection.items} width={width} />
    );
  }

  if (collection.type === "banner") {
    return (
      <Banner title={collection.title} items={collection.items} width={width} />
    );
  }


  if (collection.type === "html") {
    return (
      <Html content={collection.content} width={width} />
    );
  }

  return <React.Fragment></React.Fragment>;
}

function Home(props) {
  const { homePageCollections, width } = props;
  console.log("homePageCollections", homePageCollections);
  const sortByOrder = (collections) => {
    return collections.sort((a, b) => a.order - b.order);
  };

  return (
    <React.Fragment>
      <Box bgcolor="#ecf0f2" height="100%">
        <Container
          maxWidth={false}
          style={{ maxWidth: "1440px", margin: "0 auto" }}
        >
          <Grid container>
            {sortByOrder(homePageCollections).map((collection, i) => (
              <Grid key={i} item xs={12}>
                {i > 7 ? (
                  <LazyLoad height={300}>
                    {renderCollection(width, collection)}
                  </LazyLoad>
                ) : (
                  <React.Fragment>
                    {renderCollection(width, collection)}
                  </React.Fragment>
                )}
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
}

export default withWidth()(Home);
