import React from "react";
import { Box, Typography } from "@material-ui/core";
import styles from "./GridBox.module.scss";
import styles2 from "../../css/main.module.scss";
import "@wordpress/block-library/build-style/style.css";

export default function Banner(props) {
  const { content } = props;
  if (content.length < 1) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <React.Fragment>
      <Box
        className={[styles.brandBlocks, styles2.padded10].join(" ")}
        style={{ background: "white", margin: "5px 0 5px 0" }}
      >
        <Typography
          variant="body2"
          paragraph
          align="justify"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        ></Typography>
      </Box>
    </React.Fragment>
  );
}
