import React from "react";
import { Box } from "@material-ui/core";
import { createBannerImage } from "../helpers";

export default function Banner(props) {
  const { items, width } = props;
  const isMobile = width !== "lg" && width !== "md";
  if (items.length < 1) {
    return <React.Fragment></React.Fragment>;
  }

  const item = items.pop();
  const title = item.title;
  const image = isMobile
    ? createBannerImage(item.photo || item.cover_image, "medium")
    : createBannerImage(item.photo || item.cover_image, "large");

  return (
    <React.Fragment>
      <Box mt={1} style={{ maxHeight: "300px", heigt: "300px", width: "100%" }}>
        <img loading="lazy" style={{width: "inherit"}} src={image} alt={title} title={title}></img>
      </Box>
    </React.Fragment>
  );
}
