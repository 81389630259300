import React from "react";
import {
  Typography,
  Box,
  Button,
  GridList,
  GridListTile,
} from "@material-ui/core";
import { Link } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./Carousel.module.scss";
import styles2 from "../../css/main.module.scss";
import { createCdnURL, appURL } from "../../config/app";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
}));

const Carousel = (props) => {
  const { title, items, seeMoreLink } = props;

  const classes = useStyles();
  return (
    <Box
      className={styles2.padded10}
      style={{ background: "white", margin: "5px 0 5px 0" }}
    >
      <Box className={styles.brandBlocks} style={{ position: "relative" }}>
        <Typography variant="h6" component="h1" className={styles.dashed}>
          {title}
        </Typography>
        <Button
          title={title}
          href={`${seeMoreLink}/`}
          variant="outlined"
          size="small"
          style={{
            position: "absolute",
            top: "0px",
            right: "0",
            borderColor: "#2f86c2",
            color: "#2f86c2",
            fontWeight: "bold",
          }}
          disableElevation={true}
          className={styles.seeMore}
        >
          See more
        </Button>
        <div className={classes.root} >
          {/* <Hidden only={["sm", "xs"]}> */}
          <GridList
            className={[classes.gridList, styles.imgs].join(" ")}
            cols={7.5}
            spacing={8}
            cellHeight={"auto"}
            
            
          >
            {items.map((item, i) => (
              <GridListTile key={i} className={styles.productThumb}>
                <Link
                  to={`${appURL}/${(item.url || "#")}/`}
                  title={item.title}
                  className={styles.productLink}
                >
                  <img
                    loading="lazy"
                    alt={item.title}
                    src={createCdnURL(
                      "large",
                      item.cover_image || item.image || ""
                    )}
                  />
                  <br />
                  <span className={styles.productLinkName}>{item.title}</span>
                </Link>
              </GridListTile>
            ))}
          </GridList>
          {/* </Hidden> */}
          {/* <Hidden only={["lg", "md", "xl"]}>
            <GridList
              className={[classes.gridList, styles.imgs].join(" ")}
              cols={2.5}
              spacing={8}
              cellHeight={"auto"}
            >
              {items.map((item, i) => (
                <GridListTile  key={i}>
                  <Link to={`${item.url || "#"}`} title={item.title}>
                    <img
                      alt={item.title}
                      src={createCdnURL("large", item.cover_image || item.image || "")}
                    />
                  </Link>
                </GridListTile>
              ))}
            </GridList>
          </Hidden> */}
        </div>
        <Button
          title={title}
          href={`${seeMoreLink}/`}
          variant="outlined"
          size="small"
          style={{
            position: "absolute",
            top: "0px",
            right: "0",
            borderColor: "#2f86c2",
            color: "#2f86c2",
            fontWeight: "bold",
          }}
          disableElevation={true}
          className={styles.seeMore767}
        >
          See more
        </Button>
      </Box>
    </Box>
  );
};

export default Carousel;
