import React from "react";
import { Box } from "@material-ui/core";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/scss/image-gallery.scss";
import { createBannerImage } from "../../helpers";

export default function Slider(props) {
  const { items, width } = props;
  const isMobile = width !== "lg" && width !== "md";

  return (
    <Box mt={1} mb={1} style={{ maxHeight: "350px", minHeight: isMobile ? "130px": "230px", heigt: "300px", width: "100%" }}>
      <ImageGallery
        items={items.map((i) => ({
          original: isMobile
            ? createBannerImage(i.photo || i.cover_image, "medium")
            : createBannerImage(i.photo || i.cover_image, "large"),
          originalAlt: i.title,
          originalTitle: i.title,
          url: i.url,
        }))}
        renderItem={(item) => {
          const itemSrc = item.original;
          return (
            <a title={item.originalTitle} href={item.url}>
              <img
                className="image-gallery-image"
                src={itemSrc}
                alt={item.originalAlt}
                srcSet={item.srcSet}
                sizes={item.sizes}
                title={item.originalTitle}
                loading="lazy"
              />
            </a>
          );
        }}
        lazyLoad={true}
        showNav={false}
        showThumbnails={false}
        showPlayButton={false}
        showFullscreenButton={false}
        autoPlay={true}
      />
    </Box>
  );
}
